@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'bellflight-material-palette.scss';
@include mat.core;

// PALLETE VARS
$bell-primary-1: mat.define-palette($bell-khaki-2);
$bell-primary-2: mat.define-palette($bell-khaki-3);
$bell-primary-3: mat.define-palette($bell-khaki-4);
$bell-primary-4: mat.define-palette($bell-khaki-5);

$bell-accent-1: mat.define-palette($bell-grey-2);
$bell-accent-2: mat.define-palette($bell-grey-3);
$bell-accent-3: mat.define-palette($bell-grey-4);
$bell-accent-4: mat.define-palette($bell-grey-5);

$bell-secondary-1: mat.define-palette($bell-blue-2);
$bell-secondary-2: mat.define-palette($bell-blue-3);
$bell-secondary-3: mat.define-palette($bell-blue-4);
$bell-secondary-4: mat.define-palette($bell-blue-5);

$bell-green: mat.define-palette($bell-green-3);
$bell-blue: mat.define-palette($bell-blue-3);
$bell-khaki: mat.define-palette($bell-khaki-3);
$bell-grey: mat.define-palette($bell-grey-3);

// THEME VARS
$bellflight-mat-theme: mat.define-light-theme($bell-primary-2, $bell-accent-1);
@include mat.all-component-themes($bellflight-mat-theme);

// MARGIN VARS
$header-margin-right: 85px;
$header-margin-right-small: 53px;
$header-margin-left: 90px;
$header-margin-left-large: 40px;
$header-margin-left-medium: 50px;
$header-margin-left-small: 20px;
$side-margin: 30px;
$header-title-margin: 38px;

// PADDING VARS
$header-padding-right: 100px;
$header-padding-max500: 10px;
$header-padding-left: 90px;
$breadcrumb-padding: 10px;

$reduced-view-width: 970px;
$mobile-start-width: 768px;
$mobile-width-large: 425px;
$mobile-width-medium: 375px;
$mobile-width-small: 320px;
$tablet-start-width: 1030px;

$side-margin: 30px;

// ELEMENTS DIMENSIONS VARS
$viewer-header-toolbar-reduced-view-start: 850px;
$viewer-toolbar-height: 40px;
$viewer-headerbar-height: 35px;
$viewer-headerbar-height-mobile: 30px;
$viewer-breadcrumb-height: 35px;
$viewer-top-height: $viewer-toolbar-height + $viewer-headerbar-height;
$viewer-top-height-mobile: $viewer-headerbar-height-mobile + $viewer-breadcrumb-height + $viewer-toolbar-height;

// MOBILE BREAKPOINT VARS
$mobile-start-width: 768px;
$mobile-width-large: 425px;
$mobile-width-medium: 375px;
$mobile-width-small: 320px;

// ELEMENTS SPACING VARS
$base-8-space: 8px; // BASE SPACING BETWEEN ELEMENTS

// FONT SIZES VARS
$text-large: 38px;
$text-medium: 13px;
$text-small: 10px;
$base-font-size: 16px;
$base-font-size-rem: 1rem;
$font-size-xxxh: 3rem; // 48px
$font-size-xxh: 2.375rem; // 38px
$font-size-xh: 2rem; // 32PX
$font-size-h: 1.875rem; // 30px
$font-size-lh: 1.625rem; // 26PX
$font-size-bx2: 1.5rem; // 24PX
$font-size-xxxl: 1.375rem; // 22PX
$font-size-xxl: 1.313rem; // 21PX
$font-size-xl: 1.250rem; // 20PX
$font-size-l: 1.188rem; // 19PX
$font-size-ml: .9375rem; // 15PX
$font-size-m: .875rem; // 14PX
$font-size-ms: .813rem; // 13px
$font-size-s: .75rem; // 12PX
$font-size-xs: .6875rem; // 11PX
$font-size-xxs: .625rem; // 10px
$font-size-xxxs: .563rem; // 9px
$font-size-mini: .5rem; // 8PX

$font-family: 'aktiv-grotesk', 'Arial', sans-serif;
