$bell-red: (
  50 : #f6e3e4,
  100 : #e9b9bc,
  200 : #da8b8f,
  300 : #cb5d62,
  400 : #bf3a40,
  500 : #b4171e,
  600 : #ad141a,
  700 : #a41116,
  800 : #9c0d12,
  900 : #8c070a,
  A100 : #ffbabb,
  A200 : #ff8788,
  A400 : #ff5456,
  A700 : #ff3b3c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$bell-black: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$bell-white: (
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #ffffff,
  800 : #ffffff,
  900 : #ffffff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-blue-1: (
  50 : #e0e8ed,
  100 : #b3c6d2,
  200 : #80a1b5,
  300 : #4d7b97,
  400 : #265e80,
  500 : #00426a,
  600 : #003c62,
  700 : #003357,
  800 : #002b4d,
  900 : #001d3c,
  A100 : #72a8ff,
  A200 : #3f89ff,
  A400 : #0c6aff,
  A700 : #005df1,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$bell-blue-2: (
  50 : #e0ecf3,
  100 : #b3d0e0,
  200 : #80b1cc,
  300 : #4d91b7,
  400 : #267aa7,
  500 : #006298,
  600 : #005a90,
  700 : #005085,
  800 : #00467b,
  900 : #00346a,
  A100 : #99c3ff,
  A200 : #66a5ff,
  A400 : #3387ff,
  A700 : #1a78ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$bell-blue-3: (
  50 : #e0f5f8,
  100 : #b3e7ee,
  200 : #80d7e3,
  300 : #4dc6d8,
  400 : #26bacf,
  500 : #00aec7,
  600 : #00a7c1,
  700 : #009dba,
  800 : #0094b3,
  900 : #0084a6,
  A100 : #d0f4ff,
  A200 : #9de8ff,
  A400 : #6adbff,
  A700 : #51d5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-blue-4: (
  50 : #e8f9fb,
  100 : #c5eff4,
  200 : #9ee5ed,
  300 : #77dbe5,
  400 : #59d3e0,
  500 : #3ccbda,
  600 : #36c6d6,
  700 : #2ebed0,
  800 : #27b8cb,
  900 : #1aacc2,
  A100 : #f6feff,
  A200 : #c3f6ff,
  A400 : #90efff,
  A700 : #77ebff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-blue-5: (
  50 : #f3fbfc,
  100 : #e1f4f8,
  200 : #cdedf4,
  300 : #b8e6ef,
  400 : #a9e0eb,
  500 : #9adbe8,
  600 : #92d7e5,
  700 : #88d2e2,
  800 : #7ecdde,
  900 : #6cc4d8,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #e9fbff,
  A700 : #cff6ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-green-1: (
  50 : #e4e8e4,
  100 : #bbc6bc,
  200 : #8ea190,
  300 : #607b63,
  400 : #3e5e41,
  500 : #1c4220,
  600 : #193c1c,
  700 : #143318,
  800 : #112b13,
  900 : #091d0b,
  A100 : #5dff69,
  A200 : #2aff3a,
  A400 : #00f612,
  A700 : #00dd10,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-green-2: (
  50 : #e1ede7,
  100 : #b4d2c3,
  200 : #82b59c,
  300 : #4f9774,
  400 : #2a8056,
  500 : #046a38,
  600 : #036232,
  700 : #03572b,
  800 : #024d24,
  900 : #013c17,
  A100 : #72ff98,
  A200 : #3fff73,
  A400 : #0cff4e,
  A700 : #00f142,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-green-3: (
  50 : #e7f2e8,
  100 : #c4dec5,
  200 : #9dc89f,
  300 : #75b279,
  400 : #58a25c,
  500 : #3a913f,
  600 : #348939,
  700 : #2c7e31,
  800 : #257429,
  900 : #18621b,
  A100 : #9dffa0,
  A200 : #6aff6f,
  A400 : #37ff3d,
  A700 : #1eff24,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-green-4: (
  50 : #edf8e9,
  100 : #d3edc9,
  200 : #b6e1a5,
  300 : #98d480,
  400 : #82cb65,
  500 : #6cc24a,
  600 : #64bc43,
  700 : #59b43a,
  800 : #4fac32,
  900 : #3d9f22,
  A100 : #e2ffdb,
  A200 : #b9ffa8,
  A400 : #8fff75,
  A700 : #7bff5c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-green-5: (
  50 : #f9fbf6,
  100 : #f1f5e8,
  200 : #e7eed8,
  300 : #dde7c8,
  400 : #d6e1bd,
  500 : #cfdcb1,
  600 : #cad8aa,
  700 : #c3d3a1,
  800 : #bdce98,
  900 : #b2c588,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #faffef,
  A700 : #f2ffd5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-grey-1: (
  50 : #e5e5e5,
  100 : #bebfbf,
  200 : #929495,
  300 : #66696a,
  400 : #46484a,
  500 : #25282a,
  600 : #212425,
  700 : #1b1e1f,
  800 : #161819,
  900 : #0d0f0f,
  A100 : #67eeee,
  A200 : #3ae9e9,
  A400 : #00efef,
  A700 : #00d5d5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-grey-2: (
  50 : #eaebeb,
  100 : #cbccce,
  200 : #a9abad,
  300 : #87898c,
  400 : #6d6f73,
  500 : #53565a,
  600 : #4c4f52,
  700 : #424548,
  800 : #393c3f,
  900 : #292b2e,
  A100 : #86bef7,
  A200 : #57a5f3,
  A400 : #188bff,
  A700 : #007ffd,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$bell-grey-3: (
  50 : #eeefef,
  100 : #d6d7d7,
  200 : #babcbd,
  300 : #9ea1a3,
  400 : #8a8c8f,
  500 : #75787b,
  600 : #6d7073,
  700 : #626568,
  800 : #585b5e,
  900 : #45484b,
  A100 : #b2d5f9,
  A200 : #83bcf5,
  A400 : #46a2ff,
  A700 : #2c96ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-grey-4: (
  50 : #f4f5f5,
  100 : #e5e5e6,
  200 : #d3d4d5,
  300 : #c1c2c4,
  400 : #b4b5b7,
  500 : #a7a8aa,
  600 : #9fa0a3,
  700 : #969799,
  800 : #8c8d90,
  900 : #7c7d7f,
  A100 : #ffffff,
  A200 : #dae2fb,
  A400 : #a3baff,
  A700 : #8aa7ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-grey-5: (
  50 : #f9f9f9,
  100 : #f1f1f0,
  200 : #e8e8e7,
  300 : #dededd,
  400 : #d7d7d5,
  500 : #d0d0ce,
  600 : #cbcbc9,
  700 : #c4c4c2,
  800 : #bebebc,
  900 : #b3b3b0,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffea,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$bell-khaki-1: (
  50 : #e9e7e5,
  100 : #c8c3bf,
  200 : #a39b94,
  300 : #7e7369,
  400 : #635549,
  500 : #473729,
  600 : #403124,
  700 : #372a1f,
  800 : #2f2319,
  900 : #20160f,
  A100 : #ff9e62,
  A200 : #ff7f2f,
  A400 : #fb6000,
  A700 : #e15700,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$bell-khaki-2: (
  50 : #ebe9e5,
  100 : #cdc9be,
  200 : #aca592,
  300 : #8b8066,
  400 : #726546,
  500 : #594a25,
  600 : #514321,
  700 : #483a1b,
  800 : #3e3216,
  900 : #2e220d,
  A100 : #ffc66b,
  A200 : #ffb238,
  A400 : #ff9f05,
  A700 : #eb9000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-khaki-3: (
  50 : #f3f0e8,
  100 : #e0dac6,
  200 : #ccc2a1,
  300 : #b8aa7b,
  400 : #a8975e,
  500 : #998542,
  600 : #917d3c,
  700 : #867233,
  800 : #7c682b,
  900 : #6b551d,
  A100 : #ffe5a8,
  A200 : #ffd675,
  A400 : #ffc742,
  A700 : #ffbf29,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-khaki-4: (
  50 : #f8f6f0,
  100 : #eee9da,
  200 : #e2dbc1,
  300 : #d6cda8,
  400 : #cec296,
  500 : #c5b783,
  600 : #bfb07b,
  700 : #b8a770,
  800 : #b09f66,
  900 : #a39053,
  A100 : #ffffff,
  A200 : #fff4d4,
  A400 : #ffe8a1,
  A700 : #ffe187,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$bell-khaki-5: (
  50 : #fafaf8,
  100 : #f3f2ed,
  200 : #ebe9e2,
  300 : #e2e0d6,
  400 : #dcd9cd,
  500 : #d6d2c4,
  600 : #d1cdbe,
  700 : #ccc7b6,
  800 : #c6c1af,
  900 : #bcb6a2,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #fffffe,
  A700 : #fff9e5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
