// BELL ICON MIXIN
@mixin bell-icon-styles {
  font-family: "bell-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

// ICON CLASS
[class*="bell-icon-"] {
  &:before {
    font-family: "bell-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
  }
}

%icon {
  @include bell-icon-styles;
}

// BELL ICON FUNCTION
@function bell-icon-char($filename) {
  $char: "";

  @if $filename==360 {
    $char: "\E001";
  }

  @if $filename==Send {
    $char: "\E002";
  }

  @if $filename==accounting-bills-1 {
    $char: "\E003";
  }

  @if $filename==accounting-coins-bill {
    $char: "\E004";
  }

  @if $filename==add {
    $char: "\E005";
  }

  @if $filename==aircraft-chopper-1 {
    $char: "\E006";
  }

  @if $filename==airport {
    $char: "\E007";
  }

  @if $filename==army-badge-2 {
    $char: "\E008";
  }

  @if $filename==arrow-horizontal {
    $char: "\E009";
  }

  @if $filename==arrow-right {
    $char: "\E00A";
  }

  @if $filename==arrow-thick-down-1 {
    $char: "\E00B";
  }

  @if $filename==arrow-thick-left-1 {
    $char: "\E00C";
  }

  @if $filename==arrow-thick-right-1 {
    $char: "\E00D";
  }

  @if $filename==arrow-thick-up-1 {
    $char: "\E00E";
  }

  @if $filename==arrow-up {
    $char: "\E00F";
  }

  @if $filename==audio {
    $char: "\E010";
  }

  @if $filename==avatar {
    $char: "\E011";
  }

  @if $filename==avatar2 {
    $char: "\E012";
  }

  @if $filename==award-medal-1 {
    $char: "\E013";
  }

  @if $filename==award-star-head {
    $char: "\E014";
  }

  @if $filename==award-trophy-star {
    $char: "\E015";
  }

  @if $filename==baggage-cart {
    $char: "\E016";
  }

  @if $filename==book-flip-page {
    $char: "\E017";
  }

  @if $filename==book-open-text {
    $char: "\E018";
  }

  @if $filename==book-open {
    $char: "\E019";
  }

  @if $filename==bookmark {
    $char: "\E01A";
  }

  @if $filename==brain-1 {
    $char: "\E01B";
  }

  @if $filename==business-contract-approve {
    $char: "\E01C";
  }

  @if $filename==business-contract-handshake-sign {
    $char: "\E01D";
  }

  @if $filename==business-deal-handshake {
    $char: "\E01E";
  }

  @if $filename==calendar-check {
    $char: "\E01F";
  }

  @if $filename==calendar-clock {
    $char: "\E020";
  }

  @if $filename==calendar {
    $char: "\E021";
  }

  @if $filename==car-dashboard-fuel {
    $char: "\E022";
  }

  @if $filename==car-repair-24h-service {
    $char: "\E023";
  }

  @if $filename==cash-payment-bill {
    $char: "\E024";
  }

  @if $filename==certified-certificate {
    $char: "\E025";
  }

  @if $filename==certified-diploma-1 {
    $char: "\E026";
  }

  @if $filename==chat {
    $char: "\E027";
  }

  @if $filename==check-double {
    $char: "\E028";
  }

  @if $filename==check-payment-give {
    $char: "\E029";
  }

  @if $filename==check {
    $char: "\E02A";
  }

  @if $filename==checkbox-false {
    $char: "\E02B";
  }

  @if $filename==checkbox-true {
    $char: "\E02C";
  }

  @if $filename==chevron-down {
    $char: "\E02D";
  }

  @if $filename==chevron-left {
    $char: "\E02E";
  }

  @if $filename==chevron-right {
    $char: "\E02F";
  }

  @if $filename==chevron-up {
    $char: "\E030";
  }

  @if $filename==circle-dollar-sign {
    $char: "\E031";
  }

  @if $filename==circle-health {
    $char: "\E032";
  }

  @if $filename==circle-star {
    $char: "\E033";
  }

  @if $filename==clock {
    $char: "\E034";
  }

  @if $filename==close {
    $char: "\E035";
  }

  @if $filename==cog-double-2 {
    $char: "\E036";
  }

  @if $filename==cog {
    $char: "\E037";
  }

  @if $filename==color-brush-3 {
    $char: "\E038";
  }

  @if $filename==color-brush {
    $char: "\E039";
  }

  @if $filename==color-painting-palette {
    $char: "\E03A";
  }

  @if $filename==comment {
    $char: "\E03B";
  }

  @if $filename==compass-1 {
    $char: "\E03C";
  }

  @if $filename==compass-north {
    $char: "\E03D";
  }

  @if $filename==computer-shield {
    $char: "\E03E";
  }

  @if $filename==contract {
    $char: "\E03F";
  }

  @if $filename==credit-card {
    $char: "\E040";
  }

  @if $filename==cursor-move-target-right {
    $char: "\E041";
  }

  @if $filename==cursor-select-circle {
    $char: "\E042";
  }

  @if $filename==cursor-target {
    $char: "\E043";
  }

  @if $filename==delete {
    $char: "\E044";
  }

  @if $filename==deleteBox {
    $char: "\E045";
  }

  @if $filename==delivery-parachute {
    $char: "\E046";
  }

  @if $filename==delivery-truck-clock {
    $char: "\E047";
  }

  @if $filename==discount-star-premium {
    $char: "\E048";
  }

  @if $filename==download-arrow {
    $char: "\E049";
  }

  @if $filename==drone {
    $char: "\E04A";
  }

  @if $filename==earth-2 {
    $char: "\E04B";
  }

  @if $filename==ecology-globe-hand {
    $char: "\E04C";
  }

  @if $filename==ecology-plant-hand {
    $char: "\E04D";
  }

  @if $filename==email-action-unread {
    $char: "\E04E";
  }

  @if $filename==email {
    $char: "\E04F";
  }

  @if $filename==expand {
    $char: "\E050";
  }

  @if $filename==face-id-5 {
    $char: "\E051";
  }

  @if $filename==face-id-6 {
    $char: "\E052";
  }

  @if $filename==facebook {
    $char: "\E053";
  }

  @if $filename==filter1 {
    $char: "\E054";
  }

  @if $filename==filter2 {
    $char: "\E055";
  }

  @if $filename==flash-1 {
    $char: "\E056";
  }

  @if $filename==focus-horizontal {
    $char: "\E057";
  }

  @if $filename==focus-movement {
    $char: "\E058";
  }

  @if $filename==focus {
    $char: "\E059";
  }

  @if $filename==fullscreen {
    $char: "\E05A";
  }

  @if $filename==gallery {
    $char: "\E05B";
  }

  @if $filename==gas-load {
    $char: "\E05C";
  }

  @if $filename==gauge-dashboard-1 {
    $char: "\E05D";
  }

  @if $filename==hamburger {
    $char: "\E05E";
  }

  @if $filename==headphones-customer-support-human-1 {
    $char: "\E05F";
  }

  @if $filename==helicopter {
    $char: "\E060";
  }

  @if $filename==home {
    $char: "\E061";
  }

  @if $filename==instagram {
    $char: "\E062";
  }

  @if $filename==job {
    $char: "\E063";
  }

  @if $filename==layout-bullets {
    $char: "\E064";
  }

  @if $filename==like-2 {
    $char: "\E065";
  }

  @if $filename==like-bubble {
    $char: "\E066";
  }

  @if $filename==like-circle {
    $char: "\E067";
  }

  @if $filename==link {
    $char: "\E068";
  }

  @if $filename==linkedin {
    $char: "\E069";
  }

  @if $filename==list-sort {
    $char: "\E06A";
  }

  @if $filename==list {
    $char: "\E06B";
  }

  @if $filename==loader {
    $char: "\E06C";
  }

  @if $filename==lock-shield {
    $char: "\E06D";
  }

  @if $filename==maneuverability {
    $char: "\E06E";
  }

  @if $filename==map {
    $char: "\E06F";
  }

  @if $filename==maps-pin-1 {
    $char: "\E070";
  }

  @if $filename==matches-fire {
    $char: "\E071";
  }

  @if $filename==medical-hospital-1 {
    $char: "\E072";
  }

  @if $filename==minimize {
    $char: "\E073";
  }

  @if $filename==minus-long {
    $char: "\E074";
  }

  @if $filename==minus {
    $char: "\E075";
  }

  @if $filename==mobile-phone-2 {
    $char: "\E076";
  }

  @if $filename==navigation-arrows-left-1 {
    $char: "\E077";
  }

  @if $filename==navigation-arrows-right-1 {
    $char: "\E078";
  }

  @if $filename==navigation-arrows-up-1 {
    $char: "\E079";
  }

  @if $filename==navigation-right-circle-2 {
    $char: "\E07A";
  }

  @if $filename==network-pin {
    $char: "\E07B";
  }

  @if $filename==no-results {
    $char: "\E07C";
  }

  @if $filename==oil {
    $char: "\E07D";
  }

  @if $filename==people-closeup {
    $char: "\E07E";
  }

  @if $filename==performance-1 {
    $char: "\E07F";
  }

  @if $filename==performance-2 {
    $char: "\E080";
  }

  @if $filename==performance-3 {
    $char: "\E081";
  }

  @if $filename==performance-4 {
    $char: "\E082";
  }

  @if $filename==performance {
    $char: "\E083";
  }

  @if $filename==phone-actions-question {
    $char: "\E084";
  }

  @if $filename==phone-chatting {
    $char: "\E085";
  }

  @if $filename==phone-type {
    $char: "\E086";
  }

  @if $filename==phone {
    $char: "\E087";
  }

  @if $filename==pin-location-1 {
    $char: "\E088";
  }

  @if $filename==pin {
    $char: "\E089";
  }

  @if $filename==planet {
    $char: "\E08A";
  }

  @if $filename==play {
    $char: "\E08B";
  }

  @if $filename==plus {
    $char: "\E08C";
  }

  @if $filename==professional-network-linkedin {
    $char: "\E08D";
  }

  @if $filename==profile {
    $char: "\E08E";
  }

  @if $filename==question-help-circle {
    $char: "\E08F";
  }

  @if $filename==question-help-message {
    $char: "\E090";
  }

  @if $filename==quote {
    $char: "\E091";
  }

  @if $filename==quotes {
    $char: "\E092";
  }

  @if $filename==recycling-sign {
    $char: "\E093";
  }

  @if $filename==renewable-energy-wind-turbine {
    $char: "\E094";
  }

  @if $filename==repeat {
    $char: "\E095";
  }

  @if $filename==sea-transport-ship-cargo-birds {
    $char: "\E096";
  }

  @if $filename==sea-transport-yacht {
    $char: "\E097";
  }

  @if $filename==search {
    $char: "\E098";
  }

  @if $filename==settings {
    $char: "\E099";
  }

  @if $filename==share-1 {
    $char: "\E09A";
  }

  @if $filename==share-2 {
    $char: "\E09B";
  }

  @if $filename==shield-check-1 {
    $char: "\E09C";
  }

  @if $filename==shipment-hook {
    $char: "\E09D";
  }

  @if $filename==shipment-in-transit {
    $char: "\E09E";
  }

  @if $filename==shipment-open {
    $char: "\E09F";
  }

  @if $filename==shipment-smartphone-1 {
    $char: "\E0A0";
  }

  @if $filename==shipment-weight-kg {
    $char: "\E0A1";
  }

  @if $filename==shipment-weight-lb {
    $char: "\E0A2";
  }

  @if $filename==shopping-cart-empty-1 {
    $char: "\E0A3";
  }

  @if $filename==shopping-cart-full {
    $char: "\E0A4";
  }

  @if $filename==slideshow {
    $char: "\E0A5";
  }

  @if $filename==social-instagram {
    $char: "\E0A6";
  }

  @if $filename==social-media-facebook {
    $char: "\E0A7";
  }

  @if $filename==social-media-twitter {
    $char: "\E0A8";
  }

  @if $filename==social-video-vimeo {
    $char: "\E0A9";
  }

  @if $filename==social-video-youtube-clip {
    $char: "\E0AA";
  }

  @if $filename==stat-notch {
    $char: "\E0AB";
  }

  @if $filename==style-three-pin-repair-tool {
    $char: "\E0AC";
  }

  @if $filename==surveillance-location-1 {
    $char: "\E0AD";
  }

  @if $filename==synchronize-arrows-1 {
    $char: "\E0AE";
  }

  @if $filename==tag-new-circle {
    $char: "\E0AF";
  }

  @if $filename==tag {
    $char: "\E0B0";
  }

  @if $filename==touch-id-approved-1 {
    $char: "\E0B1";
  }

  @if $filename==touch-id-approved {
    $char: "\E0B2";
  }

  @if $filename==trekking-person {
    $char: "\E0B3";
  }

  @if $filename==trip-distance {
    $char: "\E0B4";
  }

  @if $filename==trip-multiple-destination {
    $char: "\E0B5";
  }

  @if $filename==twitter {
    $char: "\E0B6";
  }

  @if $filename==vr-user-headphones {
    $char: "\E0B7";
  }

  @if $filename==vr-user-play-1 {
    $char: "\E0B8";
  }

  @if $filename==waiting {
    $char: "\E0B9";
  }

  @if $filename==warning {
    $char: "\E0BA";
  }

  @if $filename==wench-1 {
    $char: "\E0BB";
  }

  @if $filename==wench-double {
    $char: "\E0BC";
  }

  @if $filename==wrench {
    $char: "\E0BD";
  }

  @if $filename==youtube {
    $char: "\E0BE";
  }

  @if $filename==zoom-in {
    $char: "\E0BF";
  }

  @if $filename==zoom-out {
    $char: "\E0C0";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    }

    @else {
      @include bell-icon-styles;
    }

    content: bell-icon-char($filename);
  }
}

.bell-icon-360 {
  @include icon(360);
}

.bell-icon-Send {
  @include icon(Send);
}

.bell-icon-accounting-bills-1 {
  @include icon(accounting-bills-1);
}

.bell-icon-accounting-coins-bill {
  @include icon(accounting-coins-bill);
}

.bell-icon-add {
  @include icon(add);
}

.bell-icon-aircraft-chopper-1 {
  @include icon(aircraft-chopper-1);
}

.bell-icon-airport {
  @include icon(airport);
}

.bell-icon-army-badge-2 {
  @include icon(army-badge-2);
}

.bell-icon-arrow-horizontal {
  @include icon(arrow-horizontal);
}

.bell-icon-arrow-right {
  @include icon(arrow-right);
}

.bell-icon-arrow-thick-down-1 {
  @include icon(arrow-thick-down-1);
}

.bell-icon-arrow-thick-left-1 {
  @include icon(arrow-thick-left-1);
}

.bell-icon-arrow-thick-right-1 {
  @include icon(arrow-thick-right-1);
}

.bell-icon-arrow-thick-up-1 {
  @include icon(arrow-thick-up-1);
}

.bell-icon-arrow-up {
  @include icon(arrow-up);
}

.bell-icon-audio {
  @include icon(audio);
}

.bell-icon-avatar {
  @include icon(avatar);
}

.bell-icon-avatar2 {
  @include icon(avatar2);
}

.bell-icon-award-medal-1 {
  @include icon(award-medal-1);
}

.bell-icon-award-star-head {
  @include icon(award-star-head);
}

.bell-icon-award-trophy-star {
  @include icon(award-trophy-star);
}

.bell-icon-baggage-cart {
  @include icon(baggage-cart);
}

.bell-icon-book-flip-page {
  @include icon(book-flip-page);
}

.bell-icon-book-open-text {
  @include icon(book-open-text);
}

.bell-icon-book-open {
  @include icon(book-open);
}

.bell-icon-bookmark {
  @include icon(bookmark);
}

.bell-icon-brain-1 {
  @include icon(brain-1);
}

.bell-icon-business-contract-approve {
  @include icon(business-contract-approve);
}

.bell-icon-business-contract-handshake-sign {
  @include icon(business-contract-handshake-sign);
}

.bell-icon-business-deal-handshake {
  @include icon(business-deal-handshake);
}

.bell-icon-calendar-check {
  @include icon(calendar-check);
}

.bell-icon-calendar-clock {
  @include icon(calendar-clock);
}

.bell-icon-calendar {
  @include icon(calendar);
}

.bell-icon-car-dashboard-fuel {
  @include icon(car-dashboard-fuel);
}

.bell-icon-car-repair-24h-service {
  @include icon(car-repair-24h-service);
}

.bell-icon-cash-payment-bill {
  @include icon(cash-payment-bill);
}

.bell-icon-certified-certificate {
  @include icon(certified-certificate);
}

.bell-icon-certified-diploma-1 {
  @include icon(certified-diploma-1);
}

.bell-icon-chat {
  @include icon(chat);
}

.bell-icon-check-double {
  @include icon(check-double);
}

.bell-icon-check-payment-give {
  @include icon(check-payment-give);
}

.bell-icon-check {
  @include icon(check);
}

.bell-icon-checkbox-false {
  @include icon(checkbox-false);
}

.bell-icon-checkbox-true {
  @include icon(checkbox-true);
}

.bell-icon-chevron-down {
  @include icon(chevron-down);
}

.bell-icon-chevron-left {
  @include icon(chevron-left);
}

.bell-icon-chevron-right {
  @include icon(chevron-right);
}

.bell-icon-chevron-up {
  @include icon(chevron-up);
}

.bell-icon-circle-dollar-sign {
  @include icon(circle-dollar-sign);
}

.bell-icon-circle-health {
  @include icon(circle-health);
}

.bell-icon-circle-star {
  @include icon(circle-star);
}

.bell-icon-clock {
  @include icon(clock);
}

.bell-icon-close {
  @include icon(close);
}

.bell-icon-cog-double-2 {
  @include icon(cog-double-2);
}

.bell-icon-cog {
  @include icon(cog);
}

.bell-icon-color-brush-3 {
  @include icon(color-brush-3);
}

.bell-icon-color-brush {
  @include icon(color-brush);
}

.bell-icon-color-painting-palette {
  @include icon(color-painting-palette);
}

.bell-icon-comment {
  @include icon(comment);
}

.bell-icon-compass-1 {
  @include icon(compass-1);
}

.bell-icon-compass-north {
  @include icon(compass-north);
}

.bell-icon-computer-shield {
  @include icon(computer-shield);
}

.bell-icon-contract {
  @include icon(contract);
}

.bell-icon-credit-card {
  @include icon(credit-card);
}

.bell-icon-cursor-move-target-right {
  @include icon(cursor-move-target-right);
}

.bell-icon-cursor-select-circle {
  @include icon(cursor-select-circle);
}

.bell-icon-cursor-target {
  @include icon(cursor-target);
}

.bell-icon-delete {
  @include icon(delete);
}

.bell-icon-deleteBox {
  @include icon(deleteBox);
}

.bell-icon-delivery-parachute {
  @include icon(delivery-parachute);
}

.bell-icon-delivery-truck-clock {
  @include icon(delivery-truck-clock);
}

.bell-icon-discount-star-premium {
  @include icon(discount-star-premium);
}

.bell-icon-download-arrow {
  @include icon(download-arrow);
}

.bell-icon-drone {
  @include icon(drone);
}

.bell-icon-earth-2 {
  @include icon(earth-2);
}

.bell-icon-ecology-globe-hand {
  @include icon(ecology-globe-hand);
}

.bell-icon-ecology-plant-hand {
  @include icon(ecology-plant-hand);
}

.bell-icon-email-action-unread {
  @include icon(email-action-unread);
}

.bell-icon-email {
  @include icon(email);
}

.bell-icon-expand {
  @include icon(expand);
}

.bell-icon-face-id-5 {
  @include icon(face-id-5);
}

.bell-icon-face-id-6 {
  @include icon(face-id-6);
}

.bell-icon-facebook {
  @include icon(facebook);
}

.bell-icon-filter1 {
  @include icon(filter1);
}

.bell-icon-filter2 {
  @include icon(filter2);
}

.bell-icon-flash-1 {
  @include icon(flash-1);
}

.bell-icon-focus-horizontal {
  @include icon(focus-horizontal);
}

.bell-icon-focus-movement {
  @include icon(focus-movement);
}

.bell-icon-focus {
  @include icon(focus);
}

.bell-icon-fullscreen {
  @include icon(fullscreen);
}

.bell-icon-gallery {
  @include icon(gallery);
}

.bell-icon-gas-load {
  @include icon(gas-load);
}

.bell-icon-gauge-dashboard-1 {
  @include icon(gauge-dashboard-1);
}

.bell-icon-hamburger {
  @include icon(hamburger);
}

.bell-icon-headphones-customer-support-human-1 {
  @include icon(headphones-customer-support-human-1);
}

.bell-icon-helicopter {
  @include icon(helicopter);
}

.bell-icon-home {
  @include icon(home);
}

.bell-icon-instagram {
  @include icon(instagram);
}

.bell-icon-job {
  @include icon(job);
}

.bell-icon-layout-bullets {
  @include icon(layout-bullets);
}

.bell-icon-like-2 {
  @include icon(like-2);
}

.bell-icon-like-bubble {
  @include icon(like-bubble);
}

.bell-icon-like-circle {
  @include icon(like-circle);
}

.bell-icon-link {
  @include icon(link);
}

.bell-icon-linkedin {
  @include icon(linkedin);
}

.bell-icon-list-sort {
  @include icon(list-sort);
}

.bell-icon-list {
  @include icon(list);
}

.bell-icon-loader {
  @include icon(loader);
}

.bell-icon-lock-shield {
  @include icon(lock-shield);
}

.bell-icon-maneuverability {
  @include icon(maneuverability);
}

.bell-icon-map {
  @include icon(map);
}

.bell-icon-maps-pin-1 {
  @include icon(maps-pin-1);
}

.bell-icon-matches-fire {
  @include icon(matches-fire);
}

.bell-icon-medical-hospital-1 {
  @include icon(medical-hospital-1);
}

.bell-icon-minimize {
  @include icon(minimize);
}

.bell-icon-minus-long {
  @include icon(minus-long);
}

.bell-icon-minus {
  @include icon(minus);
}

.bell-icon-mobile-phone-2 {
  @include icon(mobile-phone-2);
}

.bell-icon-navigation-arrows-left-1 {
  @include icon(navigation-arrows-left-1);
}

.bell-icon-navigation-arrows-right-1 {
  @include icon(navigation-arrows-right-1);
}

.bell-icon-navigation-arrows-up-1 {
  @include icon(navigation-arrows-up-1);
}

.bell-icon-navigation-right-circle-2 {
  @include icon(navigation-right-circle-2);
}

.bell-icon-network-pin {
  @include icon(network-pin);
}

.bell-icon-no-results {
  @include icon(no-results);
}

.bell-icon-oil {
  @include icon(oil);
}

.bell-icon-people-closeup {
  @include icon(people-closeup);
}

.bell-icon-performance-1 {
  @include icon(performance-1);
}

.bell-icon-performance-2 {
  @include icon(performance-2);
}

.bell-icon-performance-3 {
  @include icon(performance-3);
}

.bell-icon-performance-4 {
  @include icon(performance-4);
}

.bell-icon-performance {
  @include icon(performance);
}

.bell-icon-phone-actions-question {
  @include icon(phone-actions-question);
}

.bell-icon-phone-chatting {
  @include icon(phone-chatting);
}

.bell-icon-phone-type {
  @include icon(phone-type);
}

.bell-icon-phone {
  @include icon(phone);
}

.bell-icon-pin-location-1 {
  @include icon(pin-location-1);
}

.bell-icon-pin {
  @include icon(pin);
}

.bell-icon-planet {
  @include icon(planet);
}

.bell-icon-play {
  @include icon(play);
}

.bell-icon-plus {
  @include icon(plus);
}

.bell-icon-professional-network-linkedin {
  @include icon(professional-network-linkedin);
}

.bell-icon-profile {
  @include icon(profile);
}

.bell-icon-question-help-circle {
  @include icon(question-help-circle);
}

.bell-icon-question-help-message {
  @include icon(question-help-message);
}

.bell-icon-quote {
  @include icon(quote);
}

.bell-icon-quotes {
  @include icon(quotes);
}

.bell-icon-recycling-sign {
  @include icon(recycling-sign);
}

.bell-icon-renewable-energy-wind-turbine {
  @include icon(renewable-energy-wind-turbine);
}

.bell-icon-repeat {
  @include icon(repeat);
}

.bell-icon-sea-transport-ship-cargo-birds {
  @include icon(sea-transport-ship-cargo-birds);
}

.bell-icon-sea-transport-yacht {
  @include icon(sea-transport-yacht);
}

.bell-icon-search {
  @include icon(search);
}

.bell-icon-settings {
  @include icon(settings);
}

.bell-icon-share-1 {
  @include icon(share-1);
}

.bell-icon-share-2 {
  @include icon(share-2);
}

.bell-icon-shield-check-1 {
  @include icon(shield-check-1);
}

.bell-icon-shipment-hook {
  @include icon(shipment-hook);
}

.bell-icon-shipment-in-transit {
  @include icon(shipment-in-transit);
}

.bell-icon-shipment-open {
  @include icon(shipment-open);
}

.bell-icon-shipment-smartphone-1 {
  @include icon(shipment-smartphone-1);
}

.bell-icon-shipment-weight-kg {
  @include icon(shipment-weight-kg);
}

.bell-icon-shipment-weight-lb {
  @include icon(shipment-weight-lb);
}

.bell-icon-shopping-cart-empty-1 {
  @include icon(shopping-cart-empty-1);
}

.bell-icon-shopping-cart-full {
  @include icon(shopping-cart-full);
}

.bell-icon-slideshow {
  @include icon(slideshow);
}

.bell-icon-social-instagram {
  @include icon(social-instagram);
}

.bell-icon-social-media-facebook {
  @include icon(social-media-facebook);
}

.bell-icon-social-media-twitter {
  @include icon(social-media-twitter);
}

.bell-icon-social-video-vimeo {
  @include icon(social-video-vimeo);
}

.bell-icon-social-video-youtube-clip {
  @include icon(social-video-youtube-clip);
}

.bell-icon-stat-notch {
  @include icon(stat-notch);
}

.bell-icon-style-three-pin-repair-tool {
  @include icon(style-three-pin-repair-tool);
}

.bell-icon-surveillance-location-1 {
  @include icon(surveillance-location-1);
}

.bell-icon-synchronize-arrows-1 {
  @include icon(synchronize-arrows-1);
}

.bell-icon-tag-new-circle {
  @include icon(tag-new-circle);
}

.bell-icon-tag {
  @include icon(tag);
}

.bell-icon-touch-id-approved-1 {
  @include icon(touch-id-approved-1);
}

.bell-icon-touch-id-approved {
  @include icon(touch-id-approved);
}

.bell-icon-trekking-person {
  @include icon(trekking-person);
}

.bell-icon-trip-distance {
  @include icon(trip-distance);
}

.bell-icon-trip-multiple-destination {
  @include icon(trip-multiple-destination);
}

.bell-icon-twitter {
  @include icon(twitter);
}

.bell-icon-vr-user-headphones {
  @include icon(vr-user-headphones);
}

.bell-icon-vr-user-play-1 {
  @include icon(vr-user-play-1);
}

.bell-icon-waiting {
  @include icon(waiting);
}

.bell-icon-warning {
  @include icon(warning);
}

.bell-icon-wench-1 {
  @include icon(wench-1);
}

.bell-icon-wench-double {
  @include icon(wench-double);
}

.bell-icon-wrench {
  @include icon(wrench);
}

.bell-icon-youtube {
  @include icon(youtube);
}

.bell-icon-zoom-in {
  @include icon(zoom-in);
}

.bell-icon-zoom-out {
  @include icon(zoom-out);
}
