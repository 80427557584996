@charset "UTF-8";

@font-face {
  font-family: "bell-icons";
  src: url("./fonts/bell-icons/bell-icons.eot");
  src: url("./fonts/bell-icons/bell-icons.eot?#iefix") format("embedded-opentype"),
    url("./fonts/bell-icons/bell-icons.woff2") format("woff2"),
    url("./fonts/bell-icons/bell-icons.woff") format("woff"),
    url("./fonts/bell-icons/bell-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "selected-icons-v2";
  src:url("./fonts/selected-icons-v2.eot");
  src:url("./fonts/selected-icons-v2.eot?#iefix") format("embedded-opentype"),
    url("./fonts/selected-icons-v2.woff") format("woff"),
    url("./fonts/selected-icons-v2.ttf") format("truetype"),
    url("./fonts/selected-icons-v2.svg#selected-icons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "selected-icons-v2";
  content: attr(data-icon);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "selected-icons-v2";
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\61";
}
.icon-arrow-left:before {
  content: "\62";
}
.icon-arrow-down-12:before {
  content: "\63";
}
.icon-arrow-right-simple:before {
  content: "\64";
}
.icon-arrow-up-simple:before {
  content: "\65";
}
.icon-asterisk-1:before {
  content: "\66";
}
.icon-asterisk-2:before {
  content: "\67";
}
.icon-bank-notes-2:before {
  content: "\68";
}
.icon-account-group-2:before {
  content: "\69";
}
.icon-person-shield:before {
  content: "\6a";
}
.icon-account-circle-1:before {
  content: "\6b";
}
.icon-3d-sync:before {
  content: "\6c";
}
.icon-3d-screen:before {
  content: "\6d";
}
.icon-account-files:before {
  content: "\6e";
}
.icon-account-group-3:before {
  content: "\6f";
}
.icon-two-people:before {
  content: "\70";
}
.icon-alarm:before {
  content: "\71";
}
.icon-alarm-clock:before {
  content: "\72";
}
.icon-android-logo:before {
  content: "\73";
}
.icon-arrange-number:before {
  content: "\74";
}
.icon-arrow-down-box:before {
  content: "\75";
}
.icon-arrow-left-box:before {
  content: "\76";
}
.icon-arrow-up-box:before {
  content: "\77";
}
.icon-arrow-right-box:before {
  content: "\78";
}
.icon-arrow-down-circle-simple:before {
  content: "\79";
}
.icon-arrow-left-circle-simple:before {
  content: "\7a";
}
.icon-arrow-up-circle-simple:before {
  content: "\41";
}
.icon-arrow-right-circle-simple:before {
  content: "\42";
}
.icon-arrow-return:before {
  content: "\43";
}
.icon-arrow-up:before {
  content: "\44";
}
.icon-at-sign:before {
  content: "\45";
}
.icon-trashcan:before {
  content: "\46";
}
.icon-drop:before {
  content: "\47";
}
.icon-bluetooth:before {
  content: "\48";
}
.icon-book-2:before {
  content: "\49";
}
.icon-book:before {
  content: "\4a";
}
.icon-book-address:before {
  content: "\4b";
}
.icon-book-contact:before {
  content: "\4c";
}
.icon-bookmark:before {
  content: "\4d";
}
.icon-bookmark-3:before {
  content: "\4e";
}
.icon-bookmark-2:before {
  content: "\4f";
}
.icon-tag:before {
  content: "\50";
}
.icon-box-2:before {
  content: "\51";
}
.icon-bubble:before {
  content: "\52";
}
.icon-bubble-info:before {
  content: "\53";
}
.icon-bubble-message:before {
  content: "\54";
}
.icon-buildings-highrises:before {
  content: "\55";
}
.icon-building:before {
  content: "\56";
}
.icon-cc:before {
  content: "\57";
}
.icon-certificate:before {
  content: "\58";
}
.icon-chat-bubbles:before {
  content: "\59";
}
.icon-check:before {
  content: "\5a";
}
.icon-check-badge:before {
  content: "\30";
}
.icon-check-circle:before {
  content: "\31";
}
.icon-shield-check-1:before {
  content: "\32";
}
.icon-clipboard-check:before {
  content: "\33";
}
.icon-clipboard-scribble:before {
  content: "\34";
}
.icon-clock:before {
  content: "\35";
}
.icon-close:before {
  content: "\36";
}
.icon-cloud:before {
  content: "\37";
}
.icon-cloud-download:before {
  content: "\38";
}
.icon-cog-double:before {
  content: "\39";
}
.icon-cog:before {
  content: "\21";
}
.icon-drop-contrast:before {
  content: "\22";
}
.icon-triangle-right:before {
  content: "\23";
}
.icon-paper-double:before {
  content: "\24";
}
.icon-master-card:before {
  content: "\25";
}
.icon-visa:before {
  content: "\26";
}
.icon-amex:before {
  content: "\27";
}
.icon-credit-card-money:before {
  content: "\28";
}
.icon-credit-card:before {
  content: "\29";
}
.icon-cross-over:before {
  content: "\2a";
}
.icon-cross-over-2:before {
  content: "\2b";
}
.icon-cursor-arrow:before {
  content: "\2c";
}
.icon-cursor-finger:before {
  content: "\2d";
}
.icon-download-simple:before {
  content: "\2e";
}
.icon-download-arrow:before {
  content: "\2f";
}
.icon-email:before {
  content: "\3a";
}
.icon-file:before {
  content: "\3b";
}
.icon-free-sticker-circle:before {
  content: "\3c";
}
.icon-location-pin:before {
  content: "\3d";
}
.icon-lock-close:before {
  content: "\3e";
}
.icon-navigation:before {
  content: "\3f";
}
.icon-map-folded:before {
  content: "\40";
}
.icon-navigation-drawer:before {
  content: "\5b";
}
.icon-newspaper:before {
  content: "\5d";
}
.icon-new-sticker-circle:before {
  content: "\5e";
}
.icon-notepad-layer:before {
  content: "\5f";
}
.icon-notebook:before {
  content: "\60";
}
.icon-notepad-list:before {
  content: "\7b";
}
.icon-paint-brush:before {
  content: "\7c";
}
.icon-paint-brush-2:before {
  content: "\7d";
}
.icon-paperclip:before {
  content: "\7e";
}
.icon-paperclip-2:before {
  content: "\5c";
}
.icon-pencil-write:before {
  content: "\e000";
}
.icon-person:before {
  content: "\e001";
}
.icon-phone:before {
  content: "\e002";
}
.icon-phone-2:before {
  content: "\e003";
}
.icon-phone-book:before {
  content: "\e004";
}
.icon-phone-signal-full:before {
  content: "\e005";
}
.icon-pin:before {
  content: "\e006";
}
.icon-lightbulb:before {
  content: "\e047";
}
.icon-list-bullets:before {
  content: "\e048";
}
.icon-location-pin-2:before {
  content: "\e049";
}
.icon-location-pin-3:before {
  content: "\e04a";
}
.icon-search:before {
  content: "\e04b";
}
.icon-sheriff-star:before {
  content: "\e04c";
}
.icon-man-woman:before {
  content: "\e04d";
}
.icon-tools-amperemeter:before {
  content: "\e04e";
}
.icon-usb:before {
  content: "\e04f";
}
.icon-wrench:before {
  content: "\e050";
}
.icon-zigzag-horizontal:before {
  content: "\e051";
}
.icon-zigziag-vertical:before {
  content: "\e052";
}
.icon-yen-circle:before {
  content: "\e053";
}
.icon-zoom-in:before {
  content: "\e054";
}
.icon-zoom-out:before {
  content: "\e055";
}
.icon-button-power:before {
  content: "\e056";
}
.icon-book-open:before {
  content: "\e057";
}
.icon-badge-check:before {
  content: "\e058";
}
.icon-badge-star:before {
  content: "\e059";
}
.icon-rotate-left:before {
  content: "\e05a";
}
.icon-network-search:before {
  content: "\e05b";
}
.icon-navigation-show-more-1:before {
  content: "\e05c";
}
.icon-navigation-show-more-2:before {
  content: "\e05d";
}
.icon-map-pin-1:before {
  content: "\e05e";
}
.icon-logo-apple:before {
  content: "\e060";
}
.icon-lock-open-1:before {
  content: "\e061";
}
.icon-login-1:before {
  content: "\e062";
}
.icon-link-1:before {
  content: "\e063";
}
.icon-link-2:before {
  content: "\e064";
}
.icon-house-book:before {
  content: "\e065";
}
.icon-copy-1:before {
  content: "\e066";
}
.icon-coat-rack:before {
  content: "\e067";
}
.icon-clipboard-2:before {
  content: "\e068";
}
.icon-clipboard-add:before {
  content: "\e069";
}
.icon-cash-protect:before {
  content: "\e06a";
}
.icon-border-all-1:before {
  content: "\e06b";
}
.icon-triangle-up:before {
  content: "\e06c";
}
.icon-triangle-down:before {
  content: "\e06d";
}
.icon-home-6:before {
  content: "\e06e";
}
.icon-home:before {
  content: "\e06f";
}
.icon-quill:before {
  content: "\e070";
}
.icon-smiley-smile-4:before {
  content: "\e071";
}
.icon-smiley-frown-2:before {
  content: "\e072";
}
.icon-volume-medium-1:before {
  content: "\e073";
}
.icon-volume-off-1:before {
  content: "\e074";
}
.icon-wrench-double:before {
  content: "\e075";
}
.icon-wine-sparkling-cheers:before {
  content: "\e076";
}
.icon-logo-linkedin:before {
  content: "\e077";
}
.icon-logo-imessage:before {
  content: "\e078";
}
.icon-logo-instagram:before {
  content: "\e079";
}
.icon-logo-facebook:before {
  content: "\e07a";
}
.icon-logo-skype:before {
  content: "\e07b";
}
.icon-logo-twitter-bird:before {
  content: "\e07c";
}
.icon-logo-twitter:before {
  content: "\e07d";
}
.icon-logo-vimeo:before {
  content: "\e07e";
}
.icon-logo-windows:before {
  content: "\e07f";
}
.icon-logo-youtube-clip:before {
  content: "\e080";
}
.icon-logout-2:before {
  content: "\e081";
}
.icon-loop-infinite-1:before {
  content: "\e082";
}
.icon-magic-wand-2:before {
  content: "\e083";
}
.icon-paint-equipment:before {
  content: "\e084";
}
.icon-syncing:before {
  content: "\e085";
}
.icon-globe-3:before {
  content: "\e086";
}
.icon-gauge:before {
  content: "\e087";
}
.icon-food-dome-serving-1:before {
  content: "\e088";
}
.icon-cube:before {
  content: "\e089";
}
.icon-color-palette:before {
  content: "\e08a";
}
.icon-badge-number-one-2:before {
  content: "\e08b";
}
.icon-add-circle-1:before {
  content: "\e08c";
}
.icon-download-harddrive-2:before {
  content: "\e08d";
}
.icon-factory:before {
  content: "\e08e";
}
.icon-file-format-jpg-1:before {
  content: "\e08f";
}
.icon-file-format-png-1:before {
  content: "\e090";
}
.icon-3d-glasses:before {
  content: "\e007";
}
.icon-subtract-box:before {
  content: "\e008";
}
.icon-synchronize-1:before {
  content: "\e009";
}
.icon-umbrella-open:before {
  content: "\e00a";
}
.icon-arrow-up-circle:before {
  content: "\e00b";
}
.icon-keys:before {
  content: "\e00c";
}
.icon-helicopter-1:before {
  content: "\e00d";
}
.icon-helipad-circle:before {
  content: "\e00e";
}
.icon-fingerprint-login:before {
  content: "\e00f";
}
.icon-eco-factory:before {
  content: "\e010";
}
.icon-eco-nature:before {
  content: "\e011";
}
.icon-cursor-touch-1:before {
  content: "\e012";
}
.icon-building-7:before {
  content: "\e013";
}
.icon-box-1-1:before {
  content: "\e014";
}
.icon-bed-double:before {
  content: "\e015";
}
.icon-add-box:before {
  content: "\e016";
}
.icon-add-1:before {
  content: "\e017";
}
.icon-arrow-down-circle:before {
  content: "\e018";
}
.icon-arrow-left-circle:before {
  content: "\e019";
}
.icon-arrow-right-circle:before {
  content: "\e01a";
}
.icon-pause-button:before {
  content: "\e01b";
}
.icon-percent-1:before {
  content: "\e01c";
}
.icon-phone-call-incoming:before {
  content: "\e01d";
}
.icon-phone-call-outgoing:before {
  content: "\e01e";
}
.icon-phone-silent-circle:before {
  content: "\e01f";
}
.icon-plant:before {
  content: "\e020";
}
.icon-play-button-1:before {
  content: "\e021";
}
.icon-triangle-right-circle:before {
  content: "\e022";
}
.icon-protection-check:before {
  content: "\e023";
}
.icon-remove-1:before {
  content: "\e024";
}
.icon-report-problem-circle:before {
  content: "\e025";
}
.icon-report-problem-triangle:before {
  content: "\e026";
}
.icon-rss-feed:before {
  content: "\e027";
}
.icon-ruler-1:before {
  content: "\e028";
}
.icon-science-chemical:before {
  content: "\e029";
}
.icon-science-chemistry-equipment:before {
  content: "\e02a";
}
.icon-signal-warning:before {
  content: "\e02b";
}
.icon-smartphone-iphone:before {
  content: "\e02c";
}
.icon-subtract-circle-1:before {
  content: "\e02d";
}
.icon-subtract-1:before {
  content: "\e02e";
}
.icon-synchronize-4:before {
  content: "\e02f";
}
.icon-synchronize-3:before {
  content: "\e030";
}
.icon-synchronize-5:before {
  content: "\e031";
}
.icon-tablet-ipad-2:before {
  content: "\e032";
}
.icon-clipboard-check-2:before {
  content: "\e033";
}
.icon-task-2:before {
  content: "\e034";
}
.icon-thumbs-up-1:before {
  content: "\e035";
}
.icon-torch:before {
  content: "\e036";
}
.icon-transfer-arrows:before {
  content: "\e037";
}
.icon-trending-up:before {
  content: "\e038";
}
.icon-trending-down:before {
  content: "\e039";
}
.icon-tree-big-4:before {
  content: "\e03a";
}
.icon-truck-2:before {
  content: "\e03b";
}
.icon-trophy-3:before {
  content: "\e03c";
}
.icon-upload-harddrive-1:before {
  content: "\e03d";
}
.icon-user-chat-1:before {
  content: "\e03e";
}
.icon-water-hand:before {
  content: "\e03f";
}
.icon-yen-increase:before {
  content: "\e040";
}
.icon-yen-decrease:before {
  content: "\e041";
}
.icon-volume-down-1:before {
  content: "\e042";
}
.icon-volume-up-1:before {
  content: "\e043";
}
.icon-shrink-diagonal-2:before {
  content: "\e044";
}
.icon-shrink-horizontal-3:before {
  content: "\e045";
}
.icon-shrink-vertical-1:before {
  content: "\e046";
}
.icon-shrink-horizontal-1:before {
  content: "\e091";
}
.icon-logo-rss:before {
  content: "\e092";
}
.icon-layers-show:before {
  content: "\e093";
}
.icon-id-card-double:before {
  content: "\e094";
}
.icon-id-card-4:before {
  content: "\e095";
}
.icon-help-circle:before {
  content: "\e096";
}
.icon-graph-pie-1:before {
  content: "\e097";
}
.icon-graph-line-4:before {
  content: "\e098";
}
.icon-graph-bar-movement:before {
  content: "\e099";
}
.icon-graph-bar-line:before {
  content: "\e09a";
}
.icon-graph-bar-decrease:before {
  content: "\e09b";
}
.icon-folder-medium:before {
  content: "\e09c";
}
.icon-filter-picture:before {
  content: "\e09d";
}
.icon-delete-2:before {
  content: "\e09e";
}
.icon-download-simple-2:before {
  content: "\e09f";
}
.icon-badge-check-2:before {
  content: "\e0a0";
}
.icon-at-sign-circle:before {
  content: "\e0a1";
}
.icon-triangle-down-circle:before {
  content: "\e0a2";
}
.icon-alarm-sound:before {
  content: "\e0a3";
}
.icon-envelope-2:before {
  content: "\e0a4";
}
.icon-envelope:before {
  content: "\e0a5";
}
.icon-infomation-circle:before {
  content: "\e0a6";
}
.icon-list-number:before {
  content: "\e0a7";
}
.icon-location-pin-medium:before {
  content: "\e0a8";
}
.icon-medical-cross:before {
  content: "\e0a9";
}
.icon-medal:before {
  content: "\e0aa";
}
.icon-new-document-text:before {
  content: "\e0ab";
}
.icon-oil-gauge:before {
  content: "\e0ac";
}
.icon-bubble-question:before {
  content: "\e0ad";
}
.icon-tools-wrench-screwdriver:before {
  content: "\e0ae";
}
.icon-tools-hammer-2:before {
  content: "\e0af";
}
.icon-quote-1:before {
  content: "\e0b0";
}
.icon-quote-2:before {
  content: "\e0b1";
}
.icon-arrow-left-double:before {
  content: "\e0b2";
}
.icon-arrow-right-double:before {
  content: "\e0b3";
}
.icon-logo-circle-apple-filled:before {
  content: "\e0b4";
}
.icon-logo-circle-facebook-filled:before {
  content: "\e0b5";
}
.icon-logo-circle-linkedin-filled:before {
  content: "\e0b6";
}
.icon-logo-circle-instagram:before {
  content: "\e0b8";
}
.icon-logo-circle-twitter-filled:before {
  content: "\e0b9";
}
.icon-logo-circle-youtube-filled:before {
  content: "\e0ba";
}
.icon-logo-circle-instagram-filled:before {
  content: "\e0bc";
}
.icon-logo-circle-facebook-outlined:before {
  content: "\e0bd";
}
.icon-logo-circle-apple-outlined:before {
  content: "\e05f";
}
.icon-logo-instagram-outlined:before {
  content: "\e0be";
}
.icon-logo-linkedin-2:before {
  content: "\e0bf";
}
.icon-logo-skype-2:before {
  content: "\e0c0";
}
.icon-logo-twitter-bird-1:before {
  content: "\e0b7";
}
.icon-clipboard-list-2:before {
  content: "\e0bb";
}
.icon-delete-1:before {
  content: "\69";
}
.icon-dollar-sign-circle:before {
  content: "\6e";
}
.icon-dollar-increase:before {
  content: "\74";
}
