@use '@angular/material' as mat;
@import '../tp-mixins/tp-mq-mixins.scss';

// STYLES THAT MIGHT NOT BE USE
mat-tab-header {
  height: 100%;
  border-bottom: none !important;
}

// used for header/navigation material tabs
.mat {
  &-tab-links {
    height: 100%;

    a:first-child {
      justify-content: center;
      margin-left: auto;
    }
  }

  &-tab-links &-ink-bar {
    height: 3px !important;
    width: auto;
    visibility: visible;
  }

  &-tab-label,
  &-tab-link {
    font-family: $font-family !important;
    color: black !important;
    font-size: ($text-medium + 1px) !important;
    padding: 0 .75em !important;
    margin-top: 11px !important;
    width: fit-content !important;
    text-transform: uppercase;
    min-width: 120px !important;

    &:focus {
      color: mat.get-color-from-palette($bell-accent-1, 400) !important;
    }
  }

  &-tab-group,
  &-menu-item {
    font-family: $font-family !important;
  }

  &-form-field-wrapper {
    padding-bottom: 0;
  }
}

mat-form-field {
  .mat-form-field-outline {
    background-color: white !important;
  }
}

.navigation {
  .mat {
    &-menu-content {
      background-color: mat.get-color-from-palette($bell-primary-2);
    }

    &-menu-panel {
      display: block;
      position: sticky;
    }

    &-menu-item:hover {
      background-color: mat.get-color-from-palette($bell-primary-1, 100) !important;
      color: mat.get-color-from-palette($bell-accent-1) !important;
    }
  }

  &.mat-menu-panel {
    @include breakpoint-min(1379px) {
      display: none;
    }

    // Hugo: removed the '.cdk-overlay-container' selector because it was hiding mat-select values
    @include breakpoint(460px) {
      display: none;
    }
  }
}

.footer {
  .mat {
    &-menu-content {
      background-color: white;
    }

    &-menu-item:hover {
      background-color: mat.get-color-from-palette($bell-accent-4, 100) !important;
      color: mat.get-color-from-palette($bell-accent-1) !important;
    }

    &-menu-panel {
      display: none;
    }
  }

  &.mat-menu-panel {
    @include breakpoint(750px) {
      display: block;
      position: sticky;
    }
  }
}

.search-bar {
  .mat {
    &-menu-content {
      background-color: mat.get-color-from-palette($bell-accent-4, 100);
    }

    &-menu-item:hover {
      background-color: mat.get-color-from-palette($bell-accent-4, 700) !important;
      color: mat.get-color-from-palette($bell-accent-1) !important;
    }
  }
}
