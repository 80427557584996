// BREAKPOINTS MIXIN STARTS
@mixin breakpoint($max-width) {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin breakpoint-min($min-width) {
  @media only screen and (min-width: $min-width) {
    @content;
  }
}

@mixin breakpoint-max-min($max-width, $min-width) {
  @media(max-width: $max-width) and (min-width: $min-width) {
    @content;
  }
}

// BREAKPOINTS MIXIN USAGE
// .elementToBreakpoint {
//   width: 60%;
//   @include breakpoint(700px) {
//     width: 100%;
//   }
// }

// BREAKPOINTS MIXIN ENDS
