@import './bellflight-material-theme.scss';
@import './assets/styles.css';
@import './tp-styles/tp-general/tp-general.scss';
html {
  background: white;
}

@media (hover: none) and (pointer: coarse) {
  html {
    overflow: hidden;
  }
  body {
    overflow: auto;
  }
}

@media print and (hover: none) and (pointer: coarse){
  html {
    overflow: visible;
  }
  body {
    overflow: visible;
  }
}